import logo from "./img/logo-typography.png";
import "./App.scss";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <div className="logoIcon">
                    <svg
                        id="Layer_2"
                        className="logoHorseshoe"
                        data-name="Layer 2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 210.69 211.18"
                    >
                        <g id="Layer_1-2" data-name="Layer 1">
                            <path
                                class="cls-1"
                                d="m210.61,100.21c1.21,29.04-10.72,53.83-26.49,77.28-7.26,10.79-15.32,20.96-24.31,30.38-4.15,4.35-5.17,4.38-9.26.31-6.62-6.57-13.08-13.32-19.89-19.68-3.44-3.21-3.07-5.37.08-8.55,13.02-13.17,23.46-28.13,29.66-45.7,5.66-16.05,7.29-32.41,2.14-48.97-6.28-20.18-20.99-30.66-40.93-34.26-12.86-2.32-25.83-1.94-38.6,1.57-20.55,5.64-34.07,21.59-36.61,42.72-3.3,27.46,6.75,50.57,22.86,71.75,3.72,4.89,7.91,9.44,12.13,13.92,2.37,2.52,2.28,4.4-.13,6.74-7.28,7.08-14.49,14.25-21.66,21.45-2.55,2.56-4.76,2.49-7.18-.15-21.23-23.17-40.68-47.94-48.13-78.93C-5,91.55-.29,55.03,29.6,25.59,43.3,12.08,60.67,5.35,79.5,2.35c26.44-4.22,52.5-3.3,77.45,7.35,27.23,11.63,43.42,32.66,50.52,61.1,2.39,9.6,3.44,19.32,3.14,29.41Zm-41.66,70.36c-.13-3.71-3.25-6.52-7.03-6.16-3.95.37-5.99,2.79-6.05,6.62-.06,3.55,3.43,6.54,7.02,6.2,3.97-.37,5.77-2.86,6.05-6.66Zm-14.91-130.56c.06,3.54,3.4,6.89,6.8,6.49,3.76-.45,6.22-2.78,6.22-6.57,0-3.77-2.1-6.19-6.23-6.22-4.06-.03-6.86,2.56-6.79,6.31ZM23.9,74.69c-2.69.07-4.77,2.34-5.11,6.11-.32,3.46,3.04,6.92,6.53,6.7,3.74-.24,6.18-2.16,6.26-6.25.08-4.05-2.37-6.58-7.68-6.56Zm168.49,5.87c-.24-3.19-2.05-5.57-6.03-5.84-3.97-.27-6.84,2.26-6.84,6.08,0,3.93,2.13,6.17,5.92,6.63,3.51.42,6.75-2.64,6.96-6.87ZM44.38,39.24c.07,4.41,2.76,7.3,6.14,7.32,3.44.02,7-3.41,6.67-6.77-.38-3.84-2.66-6.04-6.62-6.05-4.08-.01-6.03,2.26-6.2,5.5Zm-9.86,93.82c-.26-4.02-2.42-6.32-6.17-6.83-3.43-.46-6.87,2.9-6.77,6.48.11,3.97,2.33,6.1,6.3,6.22,4.06.12,6.03-2.2,6.64-5.87Zm148.62,5.89c3.81,0,6.26-2.41,6.33-6.16.08-4.14-2.33-6.13-6.05-6.56-3.45-.4-7.01,3.08-6.7,6.58.33,3.73,2.25,6.22,6.42,6.15Zm-134.8,38.33c4.33-.38,6.63-2.5,6.93-6.35.28-3.56-3.07-6.73-6.7-6.47-3.91.28-6.1,2.44-6.13,6.45-.02,3.45,3.01,6.42,5.9,6.37Z"
                            />
                        </g>
                    </svg>
                    <svg
                        id="Layer_2"
                        data-name="Layer 2"
                        className="logoPlumbob"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 14.02 29.74"
                    >
                        <g id="Layer_1-2" data-name="Layer 1">
                            <g>
                                <path
                                    class="cls-1"
                                    d="m3.64,15.03h6.73c-1.11,4.9-2.21,9.79-3.32,14.69-.03,0-.06.01-.09.02-1.11-4.9-2.21-9.79-3.32-14.71Z"
                                />
                                <path
                                    class="cls-1"
                                    d="m7.06.01c1.1,4.87,2.2,9.75,3.31,14.64H3.64c1.11-4.9,2.21-9.78,3.31-14.65.04,0,.08,0,.11.01Z"
                                />
                                <path
                                    class="cls-1"
                                    d="m7.63,28.69c1.03-4.55,2.05-9.1,3.08-13.65h3.31c-2.11,4.59-4.2,9.14-6.29,13.7l-.1-.05Z"
                                />
                                <path
                                    class="cls-1"
                                    d="m14.02,14.66h-3.31c-1.03-4.55-2.06-9.12-3.09-13.68.03-.01.06-.02.1-.04,2.09,4.56,4.19,9.13,6.3,13.72Z"
                                />
                                <path
                                    class="cls-1"
                                    d="m6.28,28.71c-2.09-4.55-4.17-9.09-6.28-13.67h3.29c1.03,4.56,2.05,9.1,3.08,13.64-.03.01-.06.02-.1.03Z"
                                />
                                <path
                                    class="cls-1"
                                    d="m6.38,1c-1.02,4.54-2.05,9.08-3.08,13.65H0C2.1,10.07,4.19,5.52,6.28.96c.03.01.06.02.09.03Z"
                                />
                            </g>
                        </g>
                    </svg>
                </div>
                <img
                    src={logo}
                    className="logoTypography"
                    alt="THE Mane Site"
                />
                <p>
                    Coming 2023. Join the hype on{" "}
                    <a href="/discord-link">discord</a>.
                </p>
            </header>
        </div>
    );
}

export default App;
